<template>
  <div id="brandWarn">
     <Else></Else>
     <div class="warn-box">
        <div class="box-top">
            <div>
                <div class="top1">您持有<span style="color:#FF8328;">{{all}}</span>个商标，本服务基于大数据分析智能推断你有<span style="color:#FF8328;">{{this.$route.query.total}}</span>个商标疑似被侵权（根据近12期公告中正在申请的商标分析可得）</div>
                <div class="top2">建议及时关注以保护您的商标权利</div>
            </div>
            <div>
                <div>
                    <span>初审公告期</span>
                    <el-select
                    v-model="picktime"
                    class="chose1"
                    @change="chose"
                    >
                    <el-option
                        value="全部">
                    </el-option>
                    <el-option
                        v-for="item in date"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                <span v-if="endTime > 0&&picktime!='全部'" class="chose2">距离初审公告期结束还有<span style="color:#FF8328;">{{endTime}}</span>天</span>
                <span v-if="endTime < 0&&picktime!='全部'" class="chose2">初审公告期已结束<span style="color:#FF8328;">{{Math.ceil(Math.abs(endTime))}}</span>天</span>
                </div>
                <div class="chose3">注：您可以对公告期侵权预警商标提出异议，以保护商标权利</div>
            </div>
        </div>
        <div class="box-list">
            <div class="box-list1">
                <div v-for="(v,k) in list" :key="k">
                    <div class="list-img">
                        <img :src="v.imageUrl" alt="">
                    </div>
                    <div class="list-name ore">
                        <div><span>商标名称：</span><span>{{v.brandName}}</span></div>
                         <div><span style="margin-left:32px;">类别：</span><span>{{v.int_cls}}类</span></div>
                    </div>
                    <div class="list-reason ore">
                        <div><span>申请人：</span><span>{{v.tm_applicant}}</span></div>
                        <div class="list-reason-div"><span>侵权原因：</span><span>该商标因<span class="spic">{{v.types}}</span>侵权预警您已经认证的<span class="spic">{{v.myCom}}</span>持有的<span class="spic">{{v.related.brands}}</span>（第{{v.related.intCls}}类）商标</span></div>
                    </div>
                    <div class="list-button ore">
                        <div @click="openChatWindow()">异议在线咨询</div>
                        <div @click="todetail(v.int_cls,v.regis_number,v.tm_applicant)">商标查询详情</div>
                    </div>
                </div>
            </div>
            <div style="text-align: center;margin-top:30px;">
                    <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="totalNumber"
                    :current-page="current"
                    @current-change="currentChange"
                    ></el-pagination>
            </div>
        </div>
     </div>
  </div>
</template>

<script>
import {getWarnevery,knowledgeAllZhangYe} from "../../api/api";
import Else from '../ui/else.vue';
import layer from "layui-layer";
import moment from "moment";
export default {
  name: 'brandWarn',
  data(){
      return{
         picktime: "全部",
      totalNumber:0,
      all:0,
      current:1,
      date:[],
      list:[],
       endTime:0
      }
  },
  components: {
      Else
  },
  watch:{
    current(){
      this.getData2();
    }
  },
  computed:{
     
  },
  methods:{
    LayerOpen(title, url, width, height) {
			           layer.open({
			                type: 2,
			                title: title,
			                shadeClose: true,
			                shade: 0,
			                offset: "auto",
			                area: [width + 'px', height + 'px'],
			                content: url //iframe的url
			            });
			        },
			       openChatWindow() {
								//  layer.closeAll()
								this.LayerOpen("在线客服", "https://chatlink.mstatik.com/widget/standalone.html?eid=6137f9552f6e2810ce4a5959d7d71084&agentid=dc14ff41af0d0fd424b1013c2726d38b", 690,620);
								_MEIQIA('hidePanel')
						},
             async getData(){
              let res = await knowledgeAllZhangYe({
                type:1,
                comList:[this.$route.query.warncompany],
                index:1,
                size:20
              })
              if(res.data.code === 200){
                this.all = res.data.data.total;
              }
          },
           async getData2(){
            let tin = ""
            if (this.picktime == "全部"){
              tin= ""
            }else{
              tin = this.picktime
            }
              let res = await getWarnevery({
                timestamp: tin,
                comName: this.$route.query.warncompany,
                index: this.current,
                size: 10
              })
              // console.log(res)
              if(res.data.code === 200){
                this.list = res.data.dataList;
                this.totalNumber = res.data.total;
                if(this.picktime == "全部"){
                   this.date = res.data.dateList;
                    this.date = this.date.sort((a, b) => a.key - b.key);
                  this.date=  this.date.map(i => {
                    return moment(i.key).format('yyyy-MM-DD');
                  })
                }
                //子向父发送数据
            this.$nextTick(()=>{
              var height =  document.documentElement.scrollHeight;
              console.log(height)
                window.parent.postMessage(
                {
                  data: height,
                },
                "*"
              );
            })
              // console.log(this.date)
              }
          },
          currentChange(e){
            this.current = e;
          },
        todetail(val1,val2,val3){
          this.$router.push({
            path:'/brandDetail',
            query:{
              category:val1,
              regNum:val2,
              companyName:val3
            }
          })
        },
        chose(){
        //  console.log(this.picktime) 
         if (this.picktime !== "全部") {
          let a = moment(this.picktime).add(3, 'M').format('yyyy-MM-DD hh:mm:ss');
          let b = moment().format('yyyy-MM-DD hh:mm:ss');
          let m  = moment(a).diff(moment(b),'days');
          // console.log(m)
          if(m>0){
            this.endTime = m
          }else if(m == 0){
            // (moment(a).diff(moment(b))/ (1000 * 60))/(24 * 60)
            this.endTime = m
          }else{
            this.endTime = m
          }
			  }
        this.current = 1;
        this.getData2(); 
      }
  },
  mounted(){
      this.getData();
      this.getData2(); 
  }
}

</script>

<style scoped>
#brandWarn{
    padding-top: 80px;
    padding-bottom: 60px;
}
  .warn-box{
    width: 1200px;
    margin: 0 auto;
  }
  .box-top{
    margin-bottom: 38px;
    height: 150px;
  }
  .box-top>div{
    float: left;
    width: 578px;
    height: 151px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
    border-radius: 4px;
  }
  .box-top>div:nth-of-type(2){
    margin-left: 42px;
  }
  .box-top>div:nth-of-type(2)>div:nth-of-type(1){
    margin: 35px 0 0 53px;
  }
  .top1{
    width: 486px;
    font-size: 16px;
    margin: 35px 0 0 46px;
  }
  .top2{
    margin-left: 46px;
  }
  .chose1{
    margin: 0 60px 0 10px;
  }
 :deep(.el-input__inner){
    width: 132px;
    height: 25px !important;
  }
  :deep(.el-input__icon){
    line-height: normal;
  }
  .chose2{
    font-size: 14px;
  }
  .chose3{
    font-size: 14px;
    margin: 38px 0 0 53px;
    color: #FF8328;
  }
  .box-list{
    width: 1200px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.1900);
    border-radius: 4px;
    padding: 30px 0;
  }
  .box-list1{
    width: 1097px;
    margin: 0 auto;
  }
  .box-list1>div{
    height: 165px;
    border: 1px solid #DEDEDE;
    margin-bottom: 10px;
  }
  .box-list1>div>div{
    float: left;
    margin-top: 15px;
  }
   .box-list1>div>div>img{
    width: 152px;
    height: 126px;
    border: 1px solid #DEDEDE;
   }
   .list-img{
    margin: 0 50px 0 20px;
   }
   .list-name{
    width: 200px;
   }
   .ore{
    margin-top: 30px!important;
   }
   .list-reason{
    width: 380px;
   }
   .box-list1>div>div>div{
    margin-bottom: 10px;
   }
   .box-list1>div>div>div>span:nth-of-type(2){
    color:#2F2F2F;
   }
   .list-reason-div>span:nth-of-type(2){
    display: inline-block;
    width: 300px;
    float: right;
    font-size: 14px;
   }
   .list-button{
    margin-left: 135px;
   }
    .list-button>div{
        width: 99px;
        cursor: pointer;
        font-size: 14px;
         text-align: center;
        color: #165AE1;
    }
    .list-button>div:nth-of-type(2){
        height: 28px;
        line-height: 28px;
        color: #FFFFFF;
        background: #1167D5;
        border-radius: 5px;
    }
    .spic{
      color: #E90C0C;
    }
     :deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
    background: #2554B3;
   }
</style>
